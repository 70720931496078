<template>
<div id="app">


    <v-data-table :headers="headers" :items="list" sort-by="pays" class="elevation-1">

        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Table des tarifs</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-dialog v-model="dialog" max-width="800px">
                    <v-card>
                        <v-toolbar flat color="blue">
                            <v-toolbar-title>Modification des tarifs</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>

                          <v-col cols="12" sm="12" md="12">
                                    <h4>Libelle monnaie : {{detail_Formulaire.currencie}}</h4>
                          </v-col>

                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="detail_Formulaire.achat" label="Prix d'achat" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="detail_Formulaire.vente" label="Prix de vente" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="detail_Formulaire.commission" label="Commission" outlined></v-text-field>
                                </v-col>


                            </v-row>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green" dark @click="update()">Modifier</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
            </v-dialog>
        </template>


        <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="getItm(item)">
                <v-icon color="blue" large class="mr-2">
                    mdi-pencil
                </v-icon>
            </v-btn>

        </template>

    </v-data-table>

</div>
</template>

<script>
//import api from '../../../serviceApi/apiService'
import api from '@/serviceApi/apiService'

export default {
    name: "Tarif",

    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Tarif'
    },

    data: () => ({
        dialog: false,
        detail_Formulaire: "",
        headers: [{
                text: "Actions",
                align: "start",
                sortable: false,
                value: "actions",
                divider: true,
                width: '150px'

            },
            {
                text: "Libelle monnaie",
                align: "start",
                sortable: false,
                value: "currencie",
                divider: true,
                width: '150px'
            },

            {
                text: "Pays",
                align: "start",
                sortable: false,
                value: "pays",
                divider: true,
                width: '150px'
            },

            {
                text: "Prix Achat",
                align: "start",
                sortable: false,
                value: "achat",
                divider: true,
                width: '80px'
            },
            {
                text: "Prix Vente",
                align: "start",
                sortable: false,
                value: "vente",
                divider: true,
                width: '80px'
            },

            {
                text: "Commission",
                align: "start",
                sortable: false,
                value: "commission",
                divider: true,
                width: '100px'
            },


        ],

        detail_Formulaire: {
            id: '',
            currencie: '',
            achat:'',
            vente:'',
            commission:0
        },

        list: [],

    }),

    created() {
        this.readAll();

    },
    methods: {

        readAll: async function () {
            let fd = new FormData();
            const data = await api.createUpdatedata('backoffice/all-tarifs', fd);
            this.list = data;

        },


        async update() {
            let fd = new FormData();
            fd.append('id', this.detail_Formulaire.id);
            fd.append('achat', this.detail_Formulaire.achat);
            fd.append('vente', this.detail_Formulaire.vente);
            fd.append('commission', this.detail_Formulaire.commission);
            const data=await api.createUpdatedata('backoffice/update-tarif',fd);
            if (data.status == true) {
                this.showAlert(data.message, 'success');
                this.dialog = false;
                this.readAll();
                api.sendTelegram("Modification du tarif de "+this.detail_Formulaire.currencie);
                this.clearItem();

            } else {
                this.showAlert(data.message, 'error');
            }
        },


        getItm(item) {
            this.detail_Formulaire = item;
            this.dialog = true;
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                position: 'top-end',
                icon: txticone,
                title: textmessage,
                showConfirmButton: false,
                timer: 1500
            })
        },

        clearItem() {
            this.detail_Formulaire = {
                libelle_monnaie: '',
                prix_achat:'',
                prix_vente:'',
                commission:0
            };
        },


    },
};
</script>
